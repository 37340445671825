import NextLink from 'next/link';
import PropTypes from 'prop-types';

import ShopperBreadcrumbItem from 'shopper/components/Breadcrumb/V1/BreadcrumbItem';

const BreadcrumbItem = ({ children, href = null, ...rest }) =>
  href ? (
    <NextLink href={href} prefetch={false} passHref>
      <ShopperBreadcrumbItem {...rest}>{children}</ShopperBreadcrumbItem>
    </NextLink>
  ) : (
    <ShopperBreadcrumbItem {...rest}>{children}</ShopperBreadcrumbItem>
  );

BreadcrumbItem.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

export default BreadcrumbItem;
